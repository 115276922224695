import React from 'react';
import { Bell, Lock, User, Building } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const SettingsView = () => {
  const { currentUser } = useAuth();
  const isManager = currentUser?.role === 'store_manager';

  return (
    <div className="space-y-6">
      <div className="bg-gray-900 rounded-lg p-6 border border-red-600/20">
        <h2 className="text-xl font-semibold text-white mb-6">Settings</h2>
        
        <div className="space-y-6">
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-white flex items-center">
              <User className="h-5 w-5 mr-2 text-red-500" />
              Account Settings
            </h3>
            <div className="space-y-4 pl-7">
              <button className="text-gray-300 hover:text-white">
                Update Profile Information
              </button>
              <button className="text-gray-300 hover:text-white">
                Change Profile Picture
              </button>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-medium text-white flex items-center">
              <Lock className="h-5 w-5 mr-2 text-red-500" />
              Security
            </h3>
            <div className="space-y-4 pl-7">
              <button className="text-gray-300 hover:text-white">
                Change Password
              </button>
              <button className="text-gray-300 hover:text-white">
                Two-Factor Authentication
              </button>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-medium text-white flex items-center">
              <Bell className="h-5 w-5 mr-2 text-red-500" />
              Notifications
            </h3>
            <div className="space-y-4 pl-7">
              <button className="text-gray-300 hover:text-white">
                Email Notifications
              </button>
              <button className="text-gray-300 hover:text-white">
                Schedule Alerts
              </button>
            </div>
          </div>

          {isManager && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-white flex items-center">
                <Building className="h-5 w-5 mr-2 text-red-500" />
                Location Settings
              </h3>
              <div className="space-y-4 pl-7">
                <button className="text-gray-300 hover:text-white">
                  Business Hours
                </button>
                <button className="text-gray-300 hover:text-white">
                  Location Details
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsView;