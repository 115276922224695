import React from 'react';
import { Bell } from 'lucide-react';
import { Logo } from '../common/Logo';
import ProfileMenu from '../profile/ProfileMenu';

const Header = () => {
  return (
    <header className="bg-gray-900 border-b border-red-600/20">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center h-20 px-8">
          <Logo />
          
          <div className="flex items-center space-x-6">
            <button className="p-2.5 text-gray-400 hover:text-white rounded-md hover:bg-gray-800 transition-colors">
              <Bell className="h-5 w-5" />
            </button>
            <div className="pl-4 border-l border-red-600/20">
              <ProfileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;