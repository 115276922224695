import React, { useEffect, useState } from 'react';
import { AlertTriangle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getBlackoutDates } from '../../services/teamService';
import { BlackoutDate } from '../../types/team';

const BlackoutDatesView = () => {
  const { currentUser } = useAuth();
  const [blackoutDates, setBlackoutDates] = useState<BlackoutDate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBlackoutDates = async () => {
      if (!currentUser?.locationId) return;

      try {
        const dates = await getBlackoutDates(currentUser.locationId);
        setBlackoutDates(dates);
      } catch (err: any) {
        setError(err.message || 'Failed to fetch blackout dates');
      } finally {
        setLoading(false);
      }
    };

    fetchBlackoutDates();
  }, [currentUser]);

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex items-center space-x-3 mb-6">
        <AlertTriangle className="h-6 w-6 text-red-500" />
        <h2 className="text-xl font-semibold text-white">Blackout Dates</h2>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-900/50 border border-red-600 rounded text-white">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {blackoutDates.length === 0 ? (
          <p className="text-center text-gray-400 py-8">
            No blackout dates currently set
          </p>
        ) : (
          blackoutDates.map((date) => (
            <div
              key={date.id}
              className="bg-gray-800 rounded-lg p-4 border border-red-600/20"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-medium text-white">
                    {date.startDate.toLocaleDateString()} - {date.endDate.toLocaleDateString()}
                  </h3>
                  <p className="text-gray-400 mt-1">{date.reason}</p>
                </div>
                <div className="px-3 py-1 bg-red-600/20 text-red-400 rounded-md text-sm">
                  No PTO Available
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BlackoutDatesView;