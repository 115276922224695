import React, { useState, useRef, useEffect } from 'react';
import { User, LogOut, Settings } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const ProfileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  const handleSettings = () => {
    navigate('/dashboard/settings');
    setIsOpen(false);
  };

  const handleProfile = () => {
    navigate('/dashboard/profile');
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        {currentUser?.profileUrl ? (
          <img
            src={currentUser.profileUrl}
            alt={currentUser.name}
            className="h-8 w-8 rounded-full object-cover ring-2 ring-red-600 ring-offset-2 ring-offset-gray-900"
          />
        ) : (
          <div className="h-8 w-8 rounded-full bg-red-600/20 flex items-center justify-center ring-2 ring-red-600 ring-offset-2 ring-offset-gray-900">
            <User className="h-5 w-5 text-red-500" />
          </div>
        )}
        <div className="text-left">
          <p className="text-sm font-medium text-white">{currentUser?.name}</p>
          <p className="text-xs text-red-400">{currentUser?.position}</p>
        </div>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-gray-900 rounded-lg shadow-lg py-1 z-50 border border-red-600/20">
          <button
            onClick={handleProfile}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-800"
          >
            <User className="h-4 w-4 mr-2" />
            Profile
          </button>
          <button
            onClick={handleSettings}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-800"
          >
            <Settings className="h-4 w-4 mr-2" />
            Settings
          </button>
          <div className="border-t border-gray-800 my-1"></div>
          <button
            onClick={handleSignOut}
            className="flex items-center w-full px-4 py-2 text-sm text-red-400 hover:bg-gray-800"
          >
            <LogOut className="h-4 w-4 mr-2" />
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;