import { 
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { TimeOffRequest, ShiftSwapRequest, BlackoutDate } from '../types/team';

// Time Off Requests
export const createTimeOffRequest = async (data: Omit<TimeOffRequest, 'id' | 'status' | 'createdAt' | 'updatedAt'>) => {
  const requestsRef = collection(db, 'timeOffRequests');
  const docRef = await addDoc(requestsRef, {
    ...data,
    status: 'pending',
    startDate: Timestamp.fromDate(data.startDate),
    endDate: Timestamp.fromDate(data.endDate),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });
  
  return {
    id: docRef.id,
    ...data,
    status: 'pending',
    createdAt: new Date(),
    updatedAt: new Date()
  } as TimeOffRequest;
};

export const getTimeOffRequests = async (userId?: string): Promise<TimeOffRequest[]> => {
  const requestsRef = collection(db, 'timeOffRequests');
  const q = userId 
    ? query(requestsRef, where('userId', '==', userId))
    : query(requestsRef);
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    startDate: doc.data().startDate.toDate(),
    endDate: doc.data().endDate.toDate(),
    createdAt: doc.data().createdAt?.toDate() || new Date(),
    updatedAt: doc.data().updatedAt?.toDate() || new Date()
  })) as TimeOffRequest[];
};

export const updateTimeOffRequest = async (
  requestId: string,
  status: 'approved' | 'rejected'
): Promise<void> => {
  const requestRef = doc(db, 'timeOffRequests', requestId);
  await updateDoc(requestRef, {
    status,
    updatedAt: serverTimestamp()
  });
};

// Shift Swap Requests
export const createShiftSwapRequest = async (data: Omit<ShiftSwapRequest, 'id' | 'status' | 'createdAt' | 'updatedAt'>) => {
  const requestsRef = collection(db, 'shiftSwapRequests');
  const docRef = await addDoc(requestsRef, {
    ...data,
    status: 'pending',
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });
  
  return {
    id: docRef.id,
    ...data,
    status: 'pending',
    createdAt: new Date(),
    updatedAt: new Date()
  } as ShiftSwapRequest;
};

// Blackout Dates
export const createBlackoutDate = async (data: Omit<BlackoutDate, 'id' | 'createdAt' | 'updatedAt'>) => {
  const datesRef = collection(db, 'blackoutDates');
  const docRef = await addDoc(datesRef, {
    ...data,
    startDate: Timestamp.fromDate(data.startDate),
    endDate: Timestamp.fromDate(data.endDate),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });
  
  return {
    id: docRef.id,
    ...data,
    createdAt: new Date(),
    updatedAt: new Date()
  } as BlackoutDate;
};

export const getBlackoutDates = async (locationId: string): Promise<BlackoutDate[]> => {
  const datesRef = collection(db, 'blackoutDates');
  const q = query(datesRef, where('locationId', '==', locationId));
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    startDate: doc.data().startDate.toDate(),
    endDate: doc.data().endDate.toDate(),
    createdAt: doc.data().createdAt?.toDate() || new Date(),
    updatedAt: doc.data().updatedAt?.toDate() || new Date()
  })) as BlackoutDate[];
};

export const deleteBlackoutDate = async (dateId: string): Promise<void> => {
  const dateRef = doc(db, 'blackoutDates', dateId);
  await deleteDoc(dateRef);
};