import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  User as FirebaseUser,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  updatePassword
} from 'firebase/auth';
import { auth } from '../config/firebase';
import { UserProfile } from '../types/user';
import { createUserProfile, getUserProfile, updateUserProfile } from '../services/userService';

interface AuthContextType {
  currentUser: UserProfile | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string, locationId: string, locationName: string) => Promise<void>;
  signOut: () => Promise<void>;
  updateUserPassword: (currentPassword: string, newPassword: string) => Promise<void>;
  updateProfile: (updates: Partial<UserProfile>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const userProfile = await getUserProfile(firebaseUser.uid);
          if (userProfile) {
            setCurrentUser(userProfile);
          }
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        setCurrentUser(null);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const signIn = async (email: string, password: string) => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const userProfile = await getUserProfile(result.user.uid);
    if (!userProfile) {
      throw new Error('User profile not found');
    }
    setCurrentUser(userProfile);
  };

  const signUp = async (email: string, password: string, name: string, locationId: string, locationName: string) => {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    const userProfile = await createUserProfile(user.uid, {
      email,
      name,
      position: 'Store Manager',
      locationId,
      locationName,
    });
    setCurrentUser(userProfile);
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    setCurrentUser(null);
  };

  const updateUserPassword = async (currentPassword: string, newPassword: string) => {
    if (!auth.currentUser?.email) throw new Error('No user logged in');
    
    // Verify current password
    await signInWithEmailAndPassword(auth, auth.currentUser.email, currentPassword);
    
    // Update password
    await updatePassword(auth.currentUser, newPassword);
  };

  const updateProfile = async (updates: Partial<UserProfile>) => {
    if (!currentUser) throw new Error('No user logged in');
    await updateUserProfile(currentUser.id, updates);
    setCurrentUser({ ...currentUser, ...updates });
  };

  const value = {
    currentUser,
    loading,
    signIn,
    signUp,
    signOut,
    updateUserPassword,
    updateProfile,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};