import React, { useEffect, useState } from 'react';
import { Clock } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getTimeOffRequests } from '../../services/teamService';
import { TimeOffRequest } from '../../types/team';

const ApprovedTimeOffView = () => {
  const { currentUser } = useAuth();
  const [requests, setRequests] = useState<TimeOffRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRequests = async () => {
      if (!currentUser?.id) return;

      try {
        const fetchedRequests = await getTimeOffRequests(currentUser.id);
        setRequests(fetchedRequests.filter(r => r.status === 'approved'));
      } catch (err: any) {
        setError(err.message || 'Failed to fetch approved time off');
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [currentUser]);

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex items-center space-x-3 mb-6">
        <Clock className="h-6 w-6 text-red-500" />
        <h2 className="text-xl font-semibold text-white">Approved Time Off</h2>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-900/50 border border-red-600 rounded text-white">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {requests.length === 0 ? (
          <p className="text-center text-gray-400 py-8">
            No approved time off requests
          </p>
        ) : (
          requests.map((request) => (
            <div
              key={request.id}
              className="bg-gray-800 rounded-lg p-4 border border-red-600/20"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-medium text-white">
                    {request.type}
                  </h3>
                  <p className="text-gray-400 mt-1">
                    {request.startDate.toLocaleDateString()} - {request.endDate.toLocaleDateString()}
                  </p>
                  <p className="text-sm text-gray-500 mt-1">
                    {request.hours} hours per day
                  </p>
                  {request.notes && (
                    <p className="text-sm text-gray-400 mt-2">{request.notes}</p>
                  )}
                </div>
                <div className="px-3 py-1 bg-green-600/20 text-green-400 rounded-md text-sm">
                  Approved
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ApprovedTimeOffView;