import React, { useState } from 'react';
import { Calendar, ChevronLeft, ChevronRight } from 'lucide-react';
import WeeklySchedule from './WeeklySchedule';
import DailySchedule from './DailySchedule';
import ScheduleLegend from './ScheduleLegend';
import { ViewType } from '../../types/schedule';
import { useAuth } from '../../contexts/AuthContext';

const ScheduleView = () => {
  const [viewType, setViewType] = useState<ViewType>('weekly');
  const [currentDate, setCurrentDate] = useState(new Date());
  const { currentUser } = useAuth();

  const navigateSchedule = (direction: 'prev' | 'next') => {
    const newDate = new Date(currentDate);
    const days = viewType === 'weekly' ? 7 : 1;
    newDate.setDate(currentDate.getDate() + (direction === 'next' ? days : -days));
    setCurrentDate(newDate);
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-900 rounded-lg p-6 border border-red-600/20">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-4">
            <Calendar className="h-6 w-6 text-red-500" />
            <h2 className="text-2xl font-bold text-white">Schedule</h2>
            <div className="flex bg-black rounded-lg p-1 border border-red-600/30">
              <button
                onClick={() => setViewType('weekly')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                  viewType === 'weekly'
                    ? 'bg-red-600 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Weekly
              </button>
              <button
                onClick={() => setViewType('daily')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                  viewType === 'daily'
                    ? 'bg-red-600 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Daily
              </button>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigateSchedule('prev')}
              className="p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800"
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            <span className="text-white font-medium">
              {viewType === 'weekly'
                ? `Week of ${currentDate.toLocaleDateString('en-US', { 
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}`
                : currentDate.toLocaleDateString('en-US', {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
            </span>
            <button
              onClick={() => navigateSchedule('next')}
              className="p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800"
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>
        </div>

        <ScheduleLegend />

        <div className="mt-6">
          {viewType === 'weekly' ? (
            <WeeklySchedule currentDate={currentDate} />
          ) : (
            <DailySchedule currentDate={currentDate} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleView;