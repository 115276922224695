import React, { useState } from 'react';
import { Calendar, Repeat, AlertTriangle, Clock } from 'lucide-react';
import TimeOffRequestForm from './TimeOffRequestForm';
import ShiftSwapForm from './ShiftSwapForm';
import BlackoutDatesView from './BlackoutDatesView';
import ApprovedTimeOffView from './ApprovedTimeOffView';

const TeamView = () => {
  const [activeTab, setActiveTab] = useState('timeoff');

  const tabs = [
    { id: 'timeoff', label: 'Out of Office Request', icon: Calendar },
    { id: 'swap', label: 'Shift Swap', icon: Repeat },
    { id: 'blackout', label: 'Blackout Dates', icon: AlertTriangle },
    { id: 'approved', label: 'Approved Time Off', icon: Clock },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'timeoff':
        return <TimeOffRequestForm />;
      case 'swap':
        return <ShiftSwapForm />;
      case 'blackout':
        return <BlackoutDatesView />;
      case 'approved':
        return <ApprovedTimeOffView />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-900 rounded-lg p-6 border border-red-600/20">
        <div className="flex space-x-2 mb-6 bg-gray-800 p-1 rounded-lg overflow-x-auto">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-colors whitespace-nowrap ${
                activeTab === tab.id
                  ? 'bg-red-600 text-white'
                  : 'text-gray-400 hover:text-white hover:bg-gray-700'
              }`}
            >
              <tab.icon className="h-5 w-5" />
              <span>{tab.label}</span>
            </button>
          ))}
        </div>

        <div className="mt-6">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default TeamView;