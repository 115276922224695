import React, { useState, useEffect } from 'react';
import { MapPin, Edit2, Trash2, Plus } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getLocations } from '../../services/locationService';
import LocationModal from './LocationModal';
import { Location } from '../../types/user';

const LocationManagement = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const fetchedLocations = await getLocations();
      setLocations(fetchedLocations);
    } catch (err) {
      console.error('Error fetching locations:', err);
      setError('Failed to fetch locations');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateLocation = () => {
    setSelectedLocation(null);
    setShowModal(true);
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Location Management</h2>
        <button
          onClick={handleCreateLocation}
          className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Location
        </button>
      </div>

      <div className="grid gap-4">
        {locations.map((location) => (
          <div
            key={location.id}
            className="bg-gray-800 rounded-lg p-4 border border-red-600/20 hover:border-red-600/40"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="p-2 bg-red-600/20 rounded-full">
                  <MapPin className="h-6 w-6 text-red-500" />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-white">{location.name}</h3>
                  <p className="text-sm text-gray-400">
                    Hours: {location.openTime} - {location.closeTime}
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    setSelectedLocation(location);
                    setShowModal(true);
                  }}
                  className="p-2 text-gray-400 hover:text-white hover:bg-gray-700 rounded-md"
                >
                  <Edit2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <LocationModal
          location={selectedLocation}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSuccess={fetchLocations}
        />
      )}
    </div>
  );
};

export default LocationManagement;