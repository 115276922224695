import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { WeeklyTemplate } from '../../types/schedule';
import { getWeeklyTemplates } from '../../services/shiftTemplateService';
import WeeklyTemplateModal from './WeeklyTemplateModal';
import TemplateCard from './TemplateCard';

const ScheduleTemplates = () => {
  const [templates, setTemplates] = useState<WeeklyTemplate[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    fetchTemplates();
  }, [currentUser]);

  const fetchTemplates = async () => {
    if (!currentUser?.locationId) return;
    
    try {
      const fetchedTemplates = await getWeeklyTemplates(currentUser.locationId);
      setTemplates(fetchedTemplates);
    } catch (err) {
      console.error('Error fetching templates:', err);
      setError('Failed to fetch templates');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-white">Schedule Templates</h2>
        <button
          onClick={() => setShowModal(true)}
          className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Create Template
        </button>
      </div>

      {error && (
        <div className="p-4 bg-red-900/50 border border-red-600 rounded-lg text-white">
          {error}
        </div>
      )}

      <div className="grid gap-6">
        {templates.length === 0 ? (
          <div className="text-center py-12 bg-gray-800 rounded-lg border border-red-600/20">
            <p className="text-gray-400">No templates created yet</p>
          </div>
        ) : (
          templates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              onSuccess={fetchTemplates}
            />
          ))
        )}
      </div>

      {showModal && (
        <WeeklyTemplateModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSuccess={fetchTemplates}
        />
      )}
    </div>
  );
};

export default ScheduleTemplates;