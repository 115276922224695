import React, { useState } from 'react';
import { useTeamMembers } from '../../hooks/useTeamMembers';
import { User, MapPin, Edit2, Mail, UserPlus } from 'lucide-react';
import TeamManagementModal from './TeamManagementModal';
import NewUserModal from './NewUserModal';
import { UserProfile } from '../../types/user';

const TeamManagement = () => {
  const { teamMembers, loading, refetchTeamMembers } = useTeamMembers();
  const [selectedMember, setSelectedMember] = useState<UserProfile | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  const handleEdit = (member: UserProfile) => {
    setSelectedMember(member);
    setShowEditModal(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Team Management</h2>
        <button
          onClick={() => setShowNewUserModal(true)}
          className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
        >
          <UserPlus className="h-4 w-4 mr-2" />
          Add Team Member
        </button>
      </div>

      <div className="space-y-4">
        {teamMembers.map((member) => (
          <div
            key={member.id}
            className="bg-gray-800 rounded-lg p-4 border border-red-600/20 hover:border-red-600/40"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                {member.profileUrl ? (
                  <img
                    src={member.profileUrl}
                    alt={member.name}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                ) : (
                  <div className="h-12 w-12 rounded-full bg-red-600/20 flex items-center justify-center">
                    <User className="h-6 w-6 text-red-500" />
                  </div>
                )}
                <div>
                  <h3 className="text-lg font-medium text-white">{member.name}</h3>
                  <div className="flex items-center space-x-4 text-sm text-gray-400">
                    <span>{member.position}</span>
                    <div className="flex items-center space-x-1">
                      <MapPin className="h-4 w-4" />
                      <span>{member.locationName}</span>
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 mt-1">
                    <Mail className="h-4 w-4 inline mr-1" />
                    {member.email}
                  </div>
                </div>
              </div>
              <button
                onClick={() => handleEdit(member)}
                className="p-2 text-gray-400 hover:text-white hover:bg-gray-700 rounded-md"
              >
                <Edit2 className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {showEditModal && selectedMember && (
        <TeamManagementModal
          user={selectedMember}
          isOpen={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setSelectedMember(null);
          }}
          onSuccess={refetchTeamMembers}
        />
      )}

      {showNewUserModal && (
        <NewUserModal
          isOpen={showNewUserModal}
          onClose={() => setShowNewUserModal(false)}
          onSuccess={refetchTeamMembers}
        />
      )}
    </div>
  );
};

export default TeamManagement;