import { 
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { Shift } from '../types/schedule';

export const createShift = async (shiftData: Omit<Shift, 'id' | 'createdAt' | 'updatedAt'>) => {
  const shiftsRef = collection(db, 'schedules');
  const docRef = await addDoc(shiftsRef, {
    ...shiftData,
    startTime: Timestamp.fromDate(shiftData.startTime),
    endTime: Timestamp.fromDate(shiftData.endTime),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });
  
  return {
    id: docRef.id,
    ...shiftData,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now()
  };
};

export const updateShift = async (shiftId: string, shiftData: Partial<Omit<Shift, 'id' | 'createdAt' | 'updatedAt'>>) => {
  const shiftRef = doc(db, 'schedules', shiftId);
  const updates = {
    ...shiftData,
    startTime: shiftData.startTime ? Timestamp.fromDate(shiftData.startTime) : undefined,
    endTime: shiftData.endTime ? Timestamp.fromDate(shiftData.endTime) : undefined,
    updatedAt: serverTimestamp()
  };
  
  await updateDoc(shiftRef, updates);
};

export const deleteShift = async (shiftId: string) => {
  const shiftRef = doc(db, 'schedules', shiftId);
  await deleteDoc(shiftRef);
};

export const getShiftsByDateRange = async (
  locationId: string,
  startDate: Date,
  endDate: Date
): Promise<Shift[]> => {
  const shiftsRef = collection(db, 'schedules');
  const q = query(
    shiftsRef,
    where('locationId', '==', locationId),
    where('startTime', '>=', Timestamp.fromDate(startDate)),
    where('startTime', '<=', Timestamp.fromDate(endDate))
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Shift));
};

export const copySchedule = async (
  locationId: string,
  sourceStartDate: Date,
  targetStartDate: Date
) => {
  // Calculate the source end date (7 days from start)
  const sourceEndDate = new Date(sourceStartDate);
  sourceEndDate.setDate(sourceEndDate.getDate() + 7);

  // Get all shifts for the source week
  const shifts = await getShiftsByDateRange(locationId, sourceStartDate, sourceEndDate);

  // Calculate the time difference between source and target weeks
  const timeDiff = targetStartDate.getTime() - sourceStartDate.getTime();

  // Create new shifts for the target week
  const newShifts = shifts.map(async (shift) => {
    const newStartTime = new Date(shift.startTime.toDate().getTime() + timeDiff);
    const newEndTime = new Date(shift.endTime.toDate().getTime() + timeDiff);

    return createShift({
      ...shift,
      startTime: newStartTime,
      endTime: newEndTime,
    });
  });

  await Promise.all(newShifts);
};