import { 
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { WeeklyTemplate, Shift } from '../types/schedule';

export const createWeeklyTemplate = async (
  locationId: string,
  name: string,
  startTime: string,
  endTime: string,
  daysOfWeek: number[],
  requiredRoles: string[]
): Promise<WeeklyTemplate> => {
  const templatesRef = collection(db, 'weeklyTemplates');
  const docRef = await addDoc(templatesRef, {
    locationId,
    name,
    startTime,
    endTime,
    daysOfWeek,
    requiredRoles,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  });

  return {
    id: docRef.id,
    locationId,
    name,
    startTime,
    endTime,
    daysOfWeek,
    requiredRoles,
    shifts: [],
    createdAt: new Date(),
    updatedAt: new Date()
  };
};

export const getWeeklyTemplates = async (locationId: string): Promise<WeeklyTemplate[]> => {
  const templatesRef = collection(db, 'weeklyTemplates');
  const q = query(templatesRef, where('locationId', '==', locationId));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    shifts: [],
    createdAt: doc.data().createdAt?.toDate() || new Date(),
    updatedAt: doc.data().updatedAt?.toDate() || new Date()
  })) as WeeklyTemplate[];
};

export const deleteWeeklyTemplate = async (templateId: string): Promise<void> => {
  const templateRef = doc(db, 'weeklyTemplates', templateId);
  await deleteDoc(templateRef);
};

export const addTemplateToSchedule = async (template: WeeklyTemplate, startDate: Date): Promise<void> => {
  try {
    const shiftsRef = collection(db, 'schedules');
    const shifts: Partial<Shift>[] = [];

    // For each selected day of the week, create a shift
    for (const dayNum of template.daysOfWeek) {
      // Calculate the date for this day
      const shiftDate = new Date(startDate);
      const currentDayNum = shiftDate.getDay();
      const daysToAdd = (dayNum - currentDayNum + 7) % 7;
      shiftDate.setDate(shiftDate.getDate() + daysToAdd);

      // Create start and end times for this date
      const [startHour, startMinute] = template.startTime.split(':').map(Number);
      const [endHour, endMinute] = template.endTime.split(':').map(Number);

      const shiftStartTime = new Date(shiftDate);
      shiftStartTime.setHours(startHour, startMinute, 0, 0);

      const shiftEndTime = new Date(shiftDate);
      shiftEndTime.setHours(endHour, endMinute, 0, 0);

      // Create a shift for each required role
      for (const role of template.requiredRoles) {
        shifts.push({
          templateId: template.id,
          type: 'work',
          startTime: Timestamp.fromDate(shiftStartTime),
          endTime: Timestamp.fromDate(shiftEndTime),
          role,
          locationId: template.locationId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }
    }

    // Add all shifts to the database
    await Promise.all(shifts.map(shift => addDoc(shiftsRef, shift)));
  } catch (error) {
    console.error('Error adding template to schedule:', error);
    throw new Error('Failed to add template to schedule');
  }
};