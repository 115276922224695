import React, { useState, useEffect } from 'react';
import { X, Mail } from 'lucide-react';
import { UserProfile, PositionLevel } from '../../types/user';
import { useAuth } from '../../contexts/AuthContext';
import { getLocations } from '../../services/locationService';
import { updateUserProfile } from '../../services/userService';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../config/firebase';

interface TeamManagementModalProps {
  user: UserProfile;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const TeamManagementModal: React.FC<TeamManagementModalProps> = ({
  user,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [position, setPosition] = useState<PositionLevel>(user.position);
  const [locationId, setLocationId] = useState(user.locationId);
  const [locations, setLocations] = useState<Array<{ id: string; name: string }>>([]);
  const [adminPassword, setAdminPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const fetchedLocations = await getLocations();
        setLocations(fetchedLocations);
      } catch (err) {
        console.error('Error fetching locations:', err);
      }
    };

    fetchLocations();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (!adminPassword) {
        throw new Error('Please enter your password to make changes');
      }

      const selectedLocation = locations.find(loc => loc.id === locationId);
      if (!selectedLocation) {
        throw new Error('Invalid location selected');
      }

      // Update user profile
      await updateUserProfile(user.id, {
        position,
        role: position.toLowerCase().replace(' ', '_') as any,
        locationId,
        locationName: selectedLocation.name,
      }, adminPassword);

      setSuccess('User profile updated successfully');
      onSuccess();
      setTimeout(onClose, 1500);
    } catch (err: any) {
      setError(err.message || 'Failed to update user');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, user.email);
      setSuccess('Password reset email sent successfully');
    } catch (err: any) {
      setError(err.message || 'Failed to send password reset email');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  const positions: PositionLevel[] = [
    'Store Manager',
    'Assistant Manager',
    'Shift Leader',
    'Team Member'
  ];

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-lg w-full max-w-md p-6 border border-red-600/30">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-white">Edit Team Member</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-900/50 border border-red-600 rounded text-white">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-3 bg-green-900/50 border border-green-600 rounded text-white">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Name
            </label>
            <input
              type="text"
              value={user.name}
              disabled
              className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-gray-400"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Email
            </label>
            <div className="flex items-center space-x-2">
              <input
                type="email"
                value={user.email}
                disabled
                className="flex-1 bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-gray-400"
              />
              <button
                type="button"
                onClick={handlePasswordReset}
                className="px-3 py-2 bg-yellow-600/20 text-yellow-400 rounded-md hover:bg-yellow-600/30"
              >
                <Mail className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Position
            </label>
            <select
              value={position}
              onChange={(e) => setPosition(e.target.value as PositionLevel)}
              className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white"
            >
              {positions.map(pos => (
                <option key={pos} value={pos}>{pos}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Location
            </label>
            <select
              value={locationId}
              onChange={(e) => setLocationId(e.target.value)}
              className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white"
            >
              {locations.map(location => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Your Password (Required)
            </label>
            <input
              type="password"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
              className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white"
              placeholder="Enter your password to confirm changes"
              required
            />
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-300 bg-gray-800 rounded-md hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeamManagementModal;