import { doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import { UserProfile, PositionLevel } from '../types/user';

export const createUserProfile = async (
  userId: string,
  data: {
    email: string;
    name: string;
    position: PositionLevel;
    locationId: string;
    locationName: string;
  }
) => {
  const userRef = doc(db, 'users', userId);
  const role = data.position.toLowerCase().replace(' ', '_');
  
  const newUser = {
    id: userId,
    email: data.email,
    name: data.name,
    position: data.position,
    role,
    locationId: data.locationId,
    locationName: data.locationName,
    profileUrl: '',
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  };

  await setDoc(userRef, newUser);
  
  return {
    ...newUser,
    createdAt: new Date(),
    updatedAt: new Date()
  } as UserProfile;
};

export const getUserProfile = async (userId: string): Promise<UserProfile | null> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      return null;
    }

    const data = userSnap.data();
    return {
      id: userSnap.id,
      email: data.email,
      name: data.name,
      role: data.role,
      position: data.position,
      profileUrl: data.profileUrl || '',
      locationId: data.locationId,
      locationName: data.locationName,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date()
    };
  } catch (error) {
    console.error('Error getting user profile:', error);
    return null;
  }
};

export const updateUserProfile = async (
  userId: string, 
  updates: Partial<UserProfile>
): Promise<void> => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    ...updates,
    updatedAt: serverTimestamp(),
  });
};