import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getTimeOffRequests, updateTimeOffRequest } from '../../services/teamService';
import { TimeOffRequest, ShiftSwapRequest } from '../../types/team';
import { Clock, Repeat, RefreshCw } from 'lucide-react';

const ApprovalsManagement = () => {
  const { currentUser } = useAuth();
  const [timeOffRequests, setTimeOffRequests] = useState<TimeOffRequest[]>([]);
  const [shiftSwapRequests, setShiftSwapRequests] = useState<ShiftSwapRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState('');

  const fetchRequests = async () => {
    if (!currentUser?.locationId) return;

    try {
      const timeOff = await getTimeOffRequests();
      setTimeOffRequests(timeOff.filter(r => r.status === 'pending'));
      setError('');
    } catch (err: any) {
      setError(err.message || 'Failed to fetch requests');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [currentUser]);

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchRequests();
  };

  const handleApproveTimeOff = async (requestId: string) => {
    try {
      await updateTimeOffRequest(requestId, 'approved');
      await fetchRequests();
    } catch (err: any) {
      setError(err.message || 'Failed to approve request');
    }
  };

  const handleRejectTimeOff = async (requestId: string) => {
    try {
      await updateTimeOffRequest(requestId, 'rejected');
      await fetchRequests();
    } catch (err: any) {
      setError(err.message || 'Failed to reject request');
    }
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-white">Pending Approvals</h2>
        <button
          onClick={handleRefresh}
          disabled={refreshing}
          className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 disabled:opacity-50"
        >
          <RefreshCw className={`h-4 w-4 mr-2 ${refreshing ? 'animate-spin' : ''}`} />
          Refresh
        </button>
      </div>

      {error && (
        <div className="p-4 bg-red-900/50 border border-red-600 rounded-lg text-white">
          {error}
        </div>
      )}

      {/* Time Off Requests */}
      <div>
        <div className="flex items-center space-x-3 mb-4">
          <Clock className="h-6 w-6 text-red-500" />
          <h3 className="text-lg font-medium text-white">Time Off Requests</h3>
        </div>
        
        <div className="space-y-4">
          {timeOffRequests.length === 0 ? (
            <p className="text-gray-400">No pending time off requests</p>
          ) : (
            timeOffRequests.map(request => (
              <div
                key={request.id}
                className="bg-gray-800 rounded-lg p-4 border border-red-600/20"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="text-white font-medium">{request.userName}</h4>
                    <p className="text-sm text-gray-400">{request.type}</p>
                    <p className="text-sm text-gray-400">
                      {request.startDate.toLocaleDateString()} - {request.endDate.toLocaleDateString()}
                    </p>
                    <p className="text-sm text-gray-400">
                      {request.hours} hours per day
                    </p>
                    {request.notes && (
                      <p className="text-sm text-gray-500 mt-2">{request.notes}</p>
                    )}
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleApproveTimeOff(request.id)}
                      className="px-3 py-1 bg-green-600/20 text-green-400 rounded hover:bg-green-600/30"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleRejectTimeOff(request.id)}
                      className="px-3 py-1 bg-red-600/20 text-red-400 rounded hover:bg-red-600/30"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Shift Swap Requests */}
      <div>
        <div className="flex items-center space-x-3 mb-4">
          <Repeat className="h-6 w-6 text-red-500" />
          <h3 className="text-lg font-medium text-white">Shift Swap Requests</h3>
        </div>
        
        <div className="space-y-4">
          {shiftSwapRequests.length === 0 ? (
            <p className="text-gray-400">No pending shift swap requests</p>
          ) : (
            shiftSwapRequests.map(request => (
              <div
                key={request.id}
                className="bg-gray-800 rounded-lg p-4 border border-red-600/20"
              >
                {/* TODO: Implement shift swap request card */}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalsManagement;