import { 
  doc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { Location } from '../types/user';

export const createLocation = async (data: {
  name: string;
  openTime: string;
  closeTime: string;
  companyId?: string;
}): Promise<Location> => {
  const locationsRef = collection(db, 'locations');
  const locationDoc = doc(locationsRef);
  
  const locationData = {
    id: locationDoc.id,
    name: data.name,
    openTime: data.openTime,
    closeTime: data.closeTime,
    companyId: data.companyId || 'default-company',
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  };

  await setDoc(locationDoc, locationData);

  return {
    ...locationData,
    createdAt: new Date(),
    updatedAt: new Date()
  } as Location;
};

export const getLocations = async (companyId: string = 'default-company'): Promise<Location[]> => {
  const locationsRef = collection(db, 'locations');
  const q = query(locationsRef, where('companyId', '==', companyId));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
    createdAt: doc.data().createdAt?.toDate() || new Date(),
    updatedAt: doc.data().updatedAt?.toDate() || new Date()
  })) as Location[];
};

export const getLocationsByCompany = async (companyId: string = 'default-company'): Promise<Location[]> => {
  return getLocations(companyId);
};

export const getLocation = async (locationId: string): Promise<Location | null> => {
  try {
    const locationRef = doc(db, 'locations', locationId);
    const locationSnap = await getDoc(locationRef);

    if (!locationSnap.exists()) {
      return null;
    }

    const data = locationSnap.data();
    return {
      id: locationSnap.id,
      name: data.name,
      openTime: data.openTime,
      closeTime: data.closeTime,
      companyId: data.companyId,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
    } as Location;
  } catch (error) {
    console.error('Error getting location:', error);
    return null;
  }
};

export const updateLocation = async (
  locationId: string,
  updates: {
    name?: string;
    openTime?: string;
    closeTime?: string;
  }
): Promise<void> => {
  const locationRef = doc(db, 'locations', locationId);
  await updateDoc(locationRef, {
    ...updates,
    updatedAt: serverTimestamp(),
  });
};

export const deleteLocation = async (locationId: string): Promise<void> => {
  const locationRef = doc(db, 'locations', locationId);
  await deleteDoc(locationRef);
};