import React, { useState } from 'react';
import { Users, Calendar, MapPin, ClipboardCheck, Calendar as CalendarIcon } from 'lucide-react';
import TeamManagement from './TeamManagement';
import LocationManagement from './LocationManagement';
import ScheduleTemplates from './ScheduleTemplates';
import ApprovalsManagement from './ApprovalsManagement';
import BlackoutDatesManagement from './BlackoutDatesManagement';

const AdminView = () => {
  const [activeTab, setActiveTab] = useState('team');

  const tabs = [
    { id: 'team', label: 'Team Management', icon: Users },
    { id: 'schedule', label: 'Schedule Templates', icon: Calendar },
    { id: 'locations', label: 'Location Settings', icon: MapPin },
    { id: 'approvals', label: 'Approvals', icon: ClipboardCheck },
    { id: 'blackout', label: 'Blackout Dates', icon: CalendarIcon },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'team':
        return <TeamManagement />;
      case 'schedule':
        return <ScheduleTemplates />;
      case 'locations':
        return <LocationManagement />;
      case 'approvals':
        return <ApprovalsManagement />;
      case 'blackout':
        return <BlackoutDatesManagement />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-900 rounded-lg p-6 border border-red-600/20">
        <div className="flex items-center space-x-4 mb-6">
          <h2 className="text-xl font-semibold text-white">Admin Dashboard</h2>
        </div>

        <div className="flex space-x-2 mb-6 bg-gray-800 p-1 rounded-lg overflow-x-auto">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-colors whitespace-nowrap ${
                activeTab === tab.id
                  ? 'bg-red-600 text-white'
                  : 'text-gray-400 hover:text-white hover:bg-gray-700'
              }`}
            >
              <tab.icon className="h-5 w-5" />
              <span>{tab.label}</span>
            </button>
          ))}
        </div>

        <div className="mt-6">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default AdminView;