import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCSoINmU4ETi2YcjS53Tb5E-CjAeBWtmhs",
  authDomain: "manageronduty-398f6.firebaseapp.com",
  projectId: "manageronduty-398f6",
  storageBucket: "manageronduty-398f6.firebasestorage.app",
  messagingSenderId: "536161262725",
  appId: "1:536161262725:web:2552ddf7c102e10bfb9dec"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
  } else if (err.code === 'unimplemented') {
    console.warn('The current browser does not support persistence.');
  }
});