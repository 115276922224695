import React, { useState, useEffect } from 'react';
import { Plus, Calendar, Trash2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { BlackoutDate } from '../../types/team';
import { getBlackoutDates, deleteBlackoutDate } from '../../services/teamService';
import BlackoutDateModal from './BlackoutDateModal';

const BlackoutDatesManagement = () => {
  const { currentUser } = useAuth();
  const [blackoutDates, setBlackoutDates] = useState<BlackoutDate[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBlackoutDates();
  }, [currentUser]);

  const fetchBlackoutDates = async () => {
    if (!currentUser?.locationId) return;
    
    try {
      const dates = await getBlackoutDates(currentUser.locationId);
      setBlackoutDates(dates);
    } catch (err: any) {
      console.error('Error fetching blackout dates:', err);
      setError('Failed to fetch blackout dates');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (dateId: string) => {
    if (!window.confirm('Are you sure you want to delete this blackout date?')) return;

    try {
      await deleteBlackoutDate(dateId);
      await fetchBlackoutDates();
    } catch (err: any) {
      console.error('Error deleting blackout date:', err);
      setError('Failed to delete blackout date');
    }
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-white">Blackout Dates</h2>
        <button
          onClick={() => setShowModal(true)}
          className="flex items-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Blackout Date
        </button>
      </div>

      {error && (
        <div className="p-4 bg-red-900/50 border border-red-600 rounded-lg text-white">
          {error}
        </div>
      )}

      <div className="grid gap-4">
        {blackoutDates.length === 0 ? (
          <p className="text-center text-gray-400 py-8">
            No blackout dates currently set
          </p>
        ) : (
          blackoutDates.map((date) => (
            <div
              key={date.id}
              className="bg-gray-800 rounded-lg p-4 border border-red-600/20"
            >
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center space-x-2">
                    <Calendar className="h-5 w-5 text-red-500" />
                    <h3 className="text-lg font-medium text-white">
                      {date.startDate.toLocaleDateString()} - {date.endDate.toLocaleDateString()}
                    </h3>
                  </div>
                  <p className="text-gray-400 mt-2">{date.reason}</p>
                </div>
                <button
                  onClick={() => handleDelete(date.id)}
                  className="p-2 text-red-400 hover:text-red-300 hover:bg-red-900/20 rounded-md"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      {showModal && (
        <BlackoutDateModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSuccess={fetchBlackoutDates}
        />
      )}
    </div>
  );
};

export default BlackoutDatesManagement;