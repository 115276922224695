import { Timestamp } from 'firebase/firestore';

export const getWeekDates = (date: Date): Date[] => {
  const week: Date[] = [];
  const current = new Date(date);
  current.setDate(current.getDate() - current.getDay()); // Start with Sunday

  for (let i = 0; i < 7; i++) {
    week.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  return week;
};

export const formatTime = (date: Date | Timestamp): string => {
  if (date instanceof Timestamp) {
    date = date.toDate();
  }
  
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};