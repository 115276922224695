import React from 'react';

const ScheduleLegend = () => {
  const legendItems = [
    { color: 'bg-blue-500/80', label: 'Work Shift' },
    { color: 'bg-purple-500/80', label: 'PTO' },
    { color: 'bg-gray-500/80', label: 'OFF' },
  ];

  return (
    <div className="flex items-center space-x-6 bg-black/40 p-3 rounded-lg border border-red-600/20">
      {legendItems.map(({ color, label }) => (
        <div key={label} className="flex items-center space-x-2">
          <div className={`w-4 h-4 rounded ${color}`} />
          <span className="text-sm text-gray-300">{label}</span>
        </div>
      ))}
    </div>
  );
};

export default ScheduleLegend;