import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getShiftsByDateRange } from '../services/scheduleService';
import { Shift } from '../types/schedule';

export const useSchedule = (startDate: Date, endDate: Date) => {
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchShifts = async () => {
      if (!currentUser?.locationId) return;

      try {
        // Set the time to start of day and end of day for proper filtering
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);

        const fetchedShifts = await getShiftsByDateRange(
          currentUser.locationId,
          start,
          end
        );
        setShifts(fetchedShifts);
      } catch (error) {
        console.error('Error fetching shifts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchShifts();
  }, [currentUser, startDate, endDate]);

  return { shifts, loading };
};