import React, { useState } from 'react';
import { Repeat } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useSchedule } from '../../hooks/useSchedule';
import { createShiftSwapRequest } from '../../services/teamService';
import { useTeamMembers } from '../../hooks/useTeamMembers';
import { Shift } from '../../types/schedule';
import { formatTime } from '../../utils/dateUtils';

const ShiftSwapForm = () => {
  const { currentUser } = useAuth();
  const { teamMembers } = useTeamMembers();
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
  const [targetUserId, setTargetUserId] = useState('');
  const [targetShift, setTargetShift] = useState<Shift | null>(null);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Get shifts for the next 2 weeks
  const startDate = new Date();
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 14);
  const { shifts: myShifts } = useSchedule(startDate, endDate);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser || !selectedShift || !targetShift) return;

    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const targetUser = teamMembers.find(m => m.id === targetUserId);
      if (!targetUser) throw new Error('Selected user not found');

      await createShiftSwapRequest({
        requesterId: currentUser.id,
        requesterName: currentUser.name,
        requesterShiftId: selectedShift.id,
        targetUserId: targetUser.id,
        targetUserName: targetUser.name,
        targetShiftId: targetShift.id,
        notes
      });

      setSuccess('Shift swap request submitted successfully!');
      // Reset form
      setSelectedShift(null);
      setTargetUserId('');
      setTargetShift(null);
      setNotes('');
    } catch (err: any) {
      setError(err.message || 'Failed to submit request');
    } finally {
      setLoading(false);
    }
  };

  const formatShiftOption = (shift: Shift) => {
    const date = shift.startTime.toDate();
    const start = formatTime(shift.startTime.toDate());
    const end = formatTime(shift.endTime.toDate());
    return `${date.toLocaleDateString()} (${start} - ${end})`;
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex items-center space-x-3 mb-6">
        <Repeat className="h-6 w-6 text-red-500" />
        <h2 className="text-xl font-semibold text-white">Request Shift Swap</h2>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-900/50 border border-red-600 rounded text-white">
          {error}
        </div>
      )}

      {success && (
        <div className="mb-4 p-3 bg-green-900/50 border border-green-600 rounded text-white">
          {success}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Your Shift to Swap
          </label>
          <select
            value={selectedShift?.id || ''}
            onChange={(e) => {
              const shift = myShifts.find(s => s.id === e.target.value);
              setSelectedShift(shift || null);
            }}
            className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white"
            required
          >
            <option value="">Select a shift</option>
            {myShifts.map(shift => (
              <option key={shift.id} value={shift.id}>
                {formatShiftOption(shift)}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Team Member to Swap With
          </label>
          <select
            value={targetUserId}
            onChange={(e) => setTargetUserId(e.target.value)}
            className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white"
            required
          >
            <option value="">Select a team member</option>
            {teamMembers
              .filter(member => member.id !== currentUser?.id)
              .map(member => (
                <option key={member.id} value={member.id}>
                  {member.name} - {member.position}
                </option>
              ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Notes (Optional)
          </label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            rows={3}
            className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white"
            placeholder="Add any additional information..."
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
          >
            {loading ? 'Submitting...' : 'Submit Request'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShiftSwapForm;