import React from 'react';
import LoginForm from '../components/auth/LoginForm';
import { Logo } from '../components/common/Logo';

const LoginPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <Logo />
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;