import React, { useState } from 'react';
import { X, Clock, User, FileText } from 'lucide-react';
import { Shift } from '../../types/schedule';
import { useAuth } from '../../contexts/AuthContext';
import { useTeamMembers } from '../../hooks/useTeamMembers';
import { createShift, updateShift, deleteShift } from '../../services/scheduleService';

interface ShiftModalProps {
  isOpen: boolean;
  onClose: () => void;
  shift?: Shift;
  date?: Date;
}

const ShiftModal: React.FC<ShiftModalProps> = ({ isOpen, onClose, shift, date }) => {
  const { currentUser } = useAuth();
  const { teamMembers, loading: loadingTeamMembers } = useTeamMembers();
  
  const [formData, setFormData] = useState({
    employeeId: shift?.employeeId || '',
    employeeName: shift?.employeeName || '',
    role: shift?.role || '',
    type: shift?.type || 'work',
    startTime: shift?.startTime ? new Date(shift.startTime.toDate()).toISOString().slice(0, 16) : 
               date ? new Date(date.setHours(9, 0, 0, 0)).toISOString().slice(0, 16) : '',
    endTime: shift?.endTime ? new Date(shift.endTime.toDate()).toISOString().slice(0, 16) : 
             date ? new Date(date.setHours(17, 0, 0, 0)).toISOString().slice(0, 16) : '',
    notes: shift?.notes || '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleEmployeeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedEmployee = teamMembers.find(member => member.id === e.target.value);
    if (selectedEmployee) {
      setFormData({
        ...formData,
        employeeId: selectedEmployee.id,
        employeeName: selectedEmployee.name,
        role: selectedEmployee.position
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!formData.employeeId) {
      setError('Please select an employee');
      setLoading(false);
      return;
    }

    try {
      const shiftData = {
        employeeId: formData.employeeId,
        employeeName: formData.employeeName,
        role: formData.role,
        type: formData.type as 'work' | 'pto' | 'off',
        startTime: new Date(formData.startTime),
        endTime: new Date(formData.endTime),
        notes: formData.notes,
        locationId: currentUser?.locationId || '',
      };

      if (shift?.id) {
        await updateShift(shift.id, shiftData);
      } else {
        await createShift(shiftData);
      }

      onClose();
    } catch (err: any) {
      setError(err.message || 'Failed to save shift');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!shift?.id || !window.confirm('Are you sure you want to delete this shift?')) return;

    setLoading(true);
    try {
      await deleteShift(shift.id);
      onClose();
    } catch (err: any) {
      setError(err.message || 'Failed to delete shift');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-lg w-full max-w-md p-6 border border-red-600/30">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-white">
            {shift ? 'Edit Shift' : 'Create New Shift'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-900/50 border border-red-600 rounded text-white">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Employee
            </label>
            <select
              value={formData.employeeId}
              onChange={handleEmployeeChange}
              className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600"
              disabled={loadingTeamMembers}
            >
              <option value="">Select an employee</option>
              {teamMembers.map(member => (
                <option key={member.id} value={member.id}>
                  {member.name} - {member.position}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Shift Type
            </label>
            <select
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600"
            >
              <option value="work">Work</option>
              <option value="pto">PTO</option>
              <option value="off">OFF</option>
            </select>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Start Time
              </label>
              <input
                type="datetime-local"
                value={formData.startTime}
                onChange={(e) => setFormData({ ...formData, startTime: e.target.value })}
                className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                End Time
              </label>
              <input
                type="datetime-local"
                value={formData.endTime}
                onChange={(e) => setFormData({ ...formData, endTime: e.target.value })}
                className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Notes
            </label>
            <textarea
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              rows={3}
              className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600"
              placeholder="Add any notes about this shift..."
            />
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            {shift && (
              <button
                type="button"
                onClick={handleDelete}
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-gray-900 disabled:opacity-50"
              >
                Delete
              </button>
            )}
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-300 bg-gray-800 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-gray-900 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShiftModal;