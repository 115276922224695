import React, { useState } from 'react';
import { Clock, User, FileText, Edit2, Trash2 } from 'lucide-react';
import { Shift } from '../../types/schedule';
import { formatTime } from '../../utils/dateUtils';
import { useAuth } from '../../contexts/AuthContext';

interface ShiftCardProps {
  shift: Shift;
  detailed?: boolean;
  onEdit?: (shift: Shift) => void;
  onDelete?: (shift: Shift) => void;
}

export const ShiftCard: React.FC<ShiftCardProps> = ({ 
  shift, 
  detailed = false,
  onEdit,
  onDelete
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const { currentUser } = useAuth();
  const isManager = currentUser?.role === 'store_manager' || currentUser?.role === 'assistant_manager';

  const getShiftColor = (type: string) => {
    switch (type.toLowerCase()) {
      case 'pto':
        return 'bg-purple-500/80 hover:bg-purple-500/90';
      case 'off':
        return 'bg-gray-500/80 hover:bg-gray-500/90';
      case 'work':
      default:
        return 'bg-blue-500/80 hover:bg-blue-500/90';
    }
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    if (!isManager) return;
    e.preventDefault();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setShowContextMenu(true);
  };

  const handleEdit = () => {
    setShowContextMenu(false);
    onEdit?.(shift);
  };

  const handleDelete = () => {
    setShowContextMenu(false);
    if (window.confirm('Are you sure you want to delete this shift?')) {
      onDelete?.(shift);
    }
  };

  React.useEffect(() => {
    const handleClickOutside = () => setShowContextMenu(false);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <>
      <div
        className={`${getShiftColor(shift.type)} rounded-lg p-3 relative group transition-all duration-200 shadow-lg cursor-default`}
        onContextMenu={handleContextMenu}
      >
        <div className="text-white">
          <div className="flex items-center space-x-2">
            <User className="h-4 w-4" />
            <span className="font-medium">{shift.employeeName}</span>
          </div>
          <div className="flex items-center space-x-2 text-sm opacity-90 mt-1">
            <Clock className="h-4 w-4" />
            <span>
              {formatTime(shift.startTime)} - {formatTime(shift.endTime)}
            </span>
          </div>
          {detailed && (
            <>
              <div className="text-sm mt-2">{shift.role}</div>
              {shift.notes && (
                <div className="flex items-start space-x-2 mt-2 bg-black/20 p-2 rounded">
                  <FileText className="h-4 w-4 mt-0.5" />
                  <span className="text-sm">{shift.notes}</span>
                </div>
              )}
            </>
          )}
        </div>

        {isManager && (
          <div className="absolute top-2 right-2 hidden group-hover:flex space-x-1">
            <button
              onClick={handleEdit}
              className="p-1 rounded bg-black/20 hover:bg-black/40 transition-colors"
            >
              <Edit2 className="h-4 w-4 text-white" />
            </button>
            <button
              onClick={handleDelete}
              className="p-1 rounded bg-black/20 hover:bg-red-500/40 transition-colors"
            >
              <Trash2 className="h-4 w-4 text-white" />
            </button>
          </div>
        )}
      </div>

      {showContextMenu && isManager && (
        <div
          className="fixed bg-gray-900 border border-red-600/30 rounded-lg shadow-lg py-1 z-50"
          style={{
            top: contextMenuPosition.y,
            left: contextMenuPosition.x,
          }}
        >
          <button
            onClick={handleEdit}
            className="w-full px-4 py-2 text-sm text-white hover:bg-gray-800 text-left"
          >
            Edit Shift
          </button>
          <button
            onClick={handleDelete}
            className="w-full px-4 py-2 text-sm text-red-400 hover:bg-gray-800 text-left"
          >
            Delete Shift
          </button>
        </div>
      )}
    </>
  );
};