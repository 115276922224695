import React from 'react';
import { NavLink } from 'react-router-dom';
import { Calendar, Users, Settings, UsersRound } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const Sidebar = () => {
  const { currentUser } = useAuth();
  const isManager = currentUser?.role === 'store_manager' || currentUser?.role === 'assistant_manager';

  const navItems = [
    {
      to: '/dashboard',
      icon: Calendar,
      label: 'Schedule',
      end: true,
    },
    {
      to: '/dashboard/team',
      icon: UsersRound,
      label: 'Team',
    },
    {
      to: '/dashboard/admin',
      icon: Users,
      label: 'Admin',
      show: isManager,
    },
    {
      to: '/dashboard/settings',
      icon: Settings,
      label: 'Settings',
    },
  ];

  return (
    <nav className="w-64 bg-gray-900 min-h-screen border-r border-red-600/20">
      <div className="p-4 space-y-2">
        {navItems.map((item) => 
          item.show !== false && (
            <NavLink
              key={item.to}
              to={item.to}
              end={item.end}
              className={({ isActive }) =>
                `flex items-center space-x-2 px-4 py-2.5 rounded-md ${
                  isActive ? 'bg-red-600 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'
                }`
              }
            >
              <item.icon className="h-5 w-5" />
              <span>{item.label}</span>
            </NavLink>
          )
        )}
      </div>
    </nav>
  );
};

export default Sidebar;