import React, { useState } from 'react';
import { Calendar, Trash2, X } from 'lucide-react';
import { WeeklyTemplate } from '../../types/schedule';
import { deleteWeeklyTemplate, addTemplateToSchedule } from '../../services/shiftTemplateService';

interface TemplateCardProps {
  template: WeeklyTemplate;
  onSuccess: () => void;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template, onSuccess }) => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddToSchedule = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await addTemplateToSchedule(template, new Date(startDate));
      setShowDateModal(false);
      onSuccess();
    } catch (err: any) {
      setError(err.message || 'Failed to add template to schedule');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this template?')) return;
    
    try {
      await deleteWeeklyTemplate(template.id);
      onSuccess();
    } catch (err) {
      console.error('Error deleting template:', err);
    }
  };

  const formatDays = (days: number[]) => {
    if (!Array.isArray(days)) return 'No days selected';
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days.map(day => dayNames[day] || '').filter(Boolean).join(', ') || 'No days selected';
  };

  const formatRoles = (roles: string[]) => {
    if (!Array.isArray(roles)) return 'No roles selected';
    return roles.join(', ') || 'No roles selected';
  };

  return (
    <>
      <div className="bg-gray-800 rounded-lg border border-red-600/20 overflow-hidden">
        <div className="flex items-center justify-between p-4 bg-gray-900 border-b border-red-600/20">
          <h3 className="text-lg font-medium text-white">{template.name || 'Untitled'}</h3>
          <div className="flex space-x-2">
            <button
              onClick={() => setShowDateModal(true)}
              className="flex items-center px-3 py-1.5 bg-red-600/20 text-red-400 rounded hover:bg-red-600/30 transition-colors text-sm"
            >
              <Calendar className="h-4 w-4 mr-2" />
              Add to Schedule
            </button>
            <button
              onClick={handleDelete}
              className="flex items-center px-3 py-1.5 bg-red-900/20 text-red-400 rounded hover:bg-red-900/30 transition-colors text-sm"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="text-sm text-gray-400 mb-2">
            Hours: {template.startTime || '00:00'} - {template.endTime || '00:00'}
          </div>
          <div className="text-sm text-gray-400 mb-2">
            Days: {formatDays(template.daysOfWeek)}
          </div>
          <div className="text-sm text-gray-400">
            Required Roles: {formatRoles(template.requiredRoles)}
          </div>
        </div>
      </div>

      {showDateModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-gray-900 rounded-lg w-full max-w-md p-6 border border-red-600/30">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-white">Add Template to Schedule</h2>
              <button onClick={() => setShowDateModal(false)} className="text-gray-400 hover:text-white">
                <X className="h-6 w-6" />
              </button>
            </div>

            {error && (
              <div className="mb-4 p-3 bg-red-900/50 border border-red-600 rounded text-white">
                {error}
              </div>
            )}

            <form onSubmit={handleAddToSchedule} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Week Start Date
                </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-white"
                  required
                />
              </div>

              <div className="flex justify-end space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => setShowDateModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-300 bg-gray-800 rounded-md hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 disabled:opacity-50"
                >
                  {loading ? 'Adding...' : 'Add to Schedule'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateCard;