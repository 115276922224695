import React, { useState } from 'react';
import { ShiftCard } from './ShiftCard';
import { useSchedule } from '../../hooks/useSchedule';
import ShiftModal from './ShiftModal';
import { Shift } from '../../types/schedule';
import { deleteShift } from '../../services/scheduleService';

interface DailyScheduleProps {
  currentDate: Date;
}

const DailySchedule: React.FC<DailyScheduleProps> = ({ currentDate }) => {
  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);
  
  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);

  const { shifts, loading, refetchShifts } = useSchedule(startOfDay, endOfDay);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  const handleEditShift = (shift: Shift) => {
    setSelectedShift(shift);
    setIsModalOpen(true);
  };

  const handleDeleteShift = async (shift: Shift) => {
    try {
      await deleteShift(shift.id);
      refetchShifts();
    } catch (error) {
      console.error('Error deleting shift:', error);
    }
  };

  return (
    <>
      <div className="space-y-4">
        {shifts.length === 0 ? (
          <div className="text-center py-8 text-gray-400">
            No shifts scheduled for this day
          </div>
        ) : (
          shifts.map((shift) => (
            <ShiftCard 
              key={shift.id} 
              shift={shift} 
              detailed={true}
              onEdit={handleEditShift}
              onDelete={handleDeleteShift}
            />
          ))
        )}
      </div>

      {isModalOpen && (
        <ShiftModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedShift(null);
          }}
          shift={selectedShift || undefined}
          onSuccess={refetchShifts}
        />
      )}
    </>
  );
};

export default DailySchedule;