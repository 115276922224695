import React, { useState } from 'react';
import { getWeekDates } from '../../utils/dateUtils';
import { ShiftCard } from './ShiftCard';
import { useSchedule } from '../../hooks/useSchedule';
import ShiftModal from './ShiftModal';
import { Shift } from '../../types/schedule';
import { deleteShift } from '../../services/scheduleService';

interface WeeklyScheduleProps {
  currentDate: Date;
}

const WeeklySchedule: React.FC<WeeklyScheduleProps> = ({ currentDate }) => {
  const weekDates = getWeekDates(currentDate);
  const { shifts, loading, refetchShifts } = useSchedule(weekDates[0], weekDates[6]);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-600 mx-auto"></div>
      </div>
    );
  }

  const handleEditShift = (shift: Shift) => {
    setSelectedShift(shift);
    setIsModalOpen(true);
  };

  const handleDeleteShift = async (shift: Shift) => {
    try {
      await deleteShift(shift.id);
      refetchShifts();
    } catch (error) {
      console.error('Error deleting shift:', error);
    }
  };

  return (
    <>
      <div className="grid grid-cols-7 gap-4">
        {weekDates.map((date) => (
          <div key={date.toISOString()} className="min-h-[200px]">
            <div className="text-center mb-2">
              <div className="text-sm text-gray-400">
                {date.toLocaleDateString('en-US', { weekday: 'short' })}
              </div>
              <div className="text-white font-medium">
                {date.toLocaleDateString('en-US', { day: 'numeric' })}
              </div>
            </div>
            <div className="space-y-2">
              {shifts
                .filter((shift) => {
                  const shiftDate = shift.startTime.toDate();
                  return (
                    shiftDate.getDate() === date.getDate() &&
                    shiftDate.getMonth() === date.getMonth() &&
                    shiftDate.getFullYear() === date.getFullYear()
                  );
                })
                .map((shift) => (
                  <ShiftCard 
                    key={shift.id} 
                    shift={shift}
                    onEdit={handleEditShift}
                    onDelete={handleDeleteShift}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <ShiftModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedShift(null);
          }}
          shift={selectedShift || undefined}
          onSuccess={refetchShifts}
        />
      )}
    </>
  );
};

export default WeeklySchedule;