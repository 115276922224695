import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppLayout from '../components/layout/AppLayout';
import ScheduleView from '../components/schedule/ScheduleView';
import AdminView from '../components/admin/AdminView';
import TeamView from '../components/team/TeamView';
import SettingsView from '../components/settings/SettingsView';

const DashboardPage = () => {
  return (
    <AppLayout>
      <Routes>
        <Route path="/" element={<ScheduleView />} />
        <Route path="/team" element={<TeamView />} />
        <Route path="/admin" element={<AdminView />} />
        <Route path="/settings" element={<SettingsView />} />
      </Routes>
    </AppLayout>
  );
};

export default DashboardPage;